#login {
  background-color: #bde0fe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  font-weight: bold;
}

#login > div {
  width: 25%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2em;
  border-radius: 1em;
}

#login div,
input {
  margin: 0.5em;
}

#login input {
  padding: 1em;
}

#login input[type="text"] {
  width: calc(100% - 80px);
}
