#whitecode-list {
  height: 60vh;
  min-height: 500px;
  width: 100%;
  padding: 1em;
}

#whitecode-save {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#whitecode-save legend {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 500px;
}

#whitecode-save input[type="text"] {
  border: 1px solid #ccc;
  min-height: 1.5rem;
  width: 100%;
}

#whitecode-save textarea {
  border: 1px solid #ccc;
  min-height: 5rem;
}

#whitecode-save textarea.large {
  border: 1px solid #ccc;
  min-height: 10rem;
}

#whitecode-save button.large {
  padding-left: 5em;
  padding-right: 5em;
}

#whitecode-save .option,
.question {
  display: flex;
  flex-direction: row;
  border-width: 1px solid black;
}

#whitecode-save .question textarea {
  width: 100%;
}

#whitecode-save a {
  text-decoration: underline;
  margin-left: 1em;
  cursor: pointer;
}

#whitecode-list .state-button-on {
  text-align: center;
  color: green;
  font-weight: bolder;
}

#whitecode-list .state-button-off {
  background-color: blanchedalmond;
}
