#group-list {
  height: 60vh;
  min-height: 500px;
  width: 100%;
  padding: 1em;
}

#group-save {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#group-save legend {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 500px;
  border: 1px solid #ccc;
  padding: 1em;
}

#group-save legend:nth-child(2n + 1) {
  background-color: #efefef;
}

#group-save input[type="text"] {
  border: 1px solid #ccc;
  min-height: 1.5rem;
  width: 100%;
}

#group-save textarea {
  border: 1px solid #ccc;
  min-height: 5rem;
}

#group-save textarea.large {
  border: 1px solid #ccc;
  min-height: 10rem;
}

#group-save button.large {
  padding-left: 5em;
  padding-right: 5em;
}
