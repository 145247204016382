#member-list {
  height: 60vh;
  min-height: 500px;
  width: 100%;
  padding: 1em;
}

#member-save {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#member-save legend {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width: 500px;
  border: 1px solid #ccc;
  padding: 1em;
}

#member-save legend:nth-child(2n + 1) {
  background-color: #efefef;
}

#member-save input[type="text"] {
  border: 1px solid #ccc;
  min-height: 1.5rem;
  width: 100%;
}

#member-save textarea {
  border: 1px solid #ccc;
  min-height: 5rem;
}

#member-save textarea.large {
  border: 1px solid #ccc;
  min-height: 10rem;
}

#member-save button.large {
  padding-left: 5em;
  padding-right: 5em;
}

#member-save .option,
.question {
  display: flex;
  flex-direction: row;
  border-width: 1px solid black;
}

#member-save .question textarea {
  width: 100%;
}

#member-save a {
  text-decoration: underline;
  margin-left: 1em;
  cursor: pointer;
}
