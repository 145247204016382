#member-list {
  height: 70%;
  min-height: 500px;
  width: 100%;
  padding: 1em;
  background-color: white;
}

#tag-list {
  width: 600px;
  background-color: #ddd;
  padding: 1em;
}

#tag-list button {
  width: 100%;
  padding: 5px;
}

#tag-list td {
  padding: 0.5em;
}

#tag-list tr {
  background-color: white;
}

#tag-list table:nth-of-type(2n + 1) tr {
  background-color: orange;
}

#tag-list table:nth-of-type(2n) td:first-child {
  font-weight: bold;
}

#loading {
  width: 100%;
  height: 100vh;
  background-color: lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
}

#target-list-selection {
  border: 1px solid #ccc;
}

#target-list-selection td {
  padding: 0.5em;
}

#target-list-selection tr:nth-of-type(2n) {
  background-color: rgb(253, 232, 235);
}

#target-list-selection select {
  width: 100%;
  height: 1.5em;
  width: 20em;
}

#target-list-selection button[data-action="add"] {
  background-color: lightgreen;
  border: 0;
  padding: 5px 30px;
}

#target-list-selection button[data-action="delete"] {
  background-color: red;
  border: 0;
  padding: 5px 10px;
  font-size: xx-small;
  color: white;
}
