.root-wrapper {
  display: flex;
  flex-direction: row;
  /* background-color: #efefef; */
  min-height: 100vh;
  position: relative;
}

.root-wrapper .navbar {
  width: 200px;
  min-width: 200px;
  background-color: darkred;
  padding: 10px;
  color: white;
}

.root-wrapper .navbar a {
  position: relative;
  color: white;
  text-decoration: none;
  margin-bottom: 0.5em;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  padding: 1em;
  padding-left: 1.25em;
}

.root-wrapper .navbar a.active {
  color: yellow;
  font-weight: bolder;
  text-decoration: underline;
}

.root-wrapper .navbar a::before {
  position: absolute;
  top: 15px;
  left: 0px;
  content: "➥";
  font-size: 1em;
  font-weight: bold;
  color: pink;
}

/* MOBILE */

.root-wrapper-mobile {
  min-height: 100vh;
}

.root-wrapper-mobile .navbar-mobile nav {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  background-color: red;
  justify-content: space-between;
  z-index: 1;
}

.root-wrapper-mobile .navbar-mobile div {
  display: inline-block;
  margin: 1em;
}

.root-wrapper-mobile .navbar-mobile a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
