#member-list-mobile {
  height: 100%;
  padding: 0em 1em 0em 1em;
}

#member-list-mobile table {
  border: 1px solid gray;
}

#member-list-mobile td {
  padding: 5px;
}

#member-list-mobile tr:nth-of-type(2n) {
  background-color: aliceblue;
}
