#content-list {
  height: 60vh;
  min-height: 500px;
  width: 100%;
  padding: 1em;
}

#content-save {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#content-save legend {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  min-width: 500px;
  border: 1px solid #ccc;
  padding: 1em;
}

#content-save legend:nth-child(2n + 1) {
  background-color: #efefef;
}

#content-save input[type="text"] {
  border: 1px solid #ccc;
  min-height: 1.5rem;
  width: 100%;
}

#content-save textarea {
  border: 1px solid #ccc;
  min-height: 5rem;
}

#content-save textarea.large {
  border: 1px solid #ccc;
  min-height: 10rem;
}

#content-save label.counter {
  font-size: 10px;
}

#content-save button.large {
  padding-left: 5em;
  padding-right: 5em;
}

#content-save .MuiInputBase-root {
  background-color: white;
  border: 0.5px solid gray;
}

#content-save .gallery {
  display: flex;
  flex-direction: row;
  margin: 1em 0 0 0;
}

#content-save .gallery button {
  width: 85%;
  color: red;
}

#content-save .gallery-item {
  width: 100px;
  height: 100px;
  margin-right: 1em;
}

#content-save .imagebox {
  padding: 1em;
  background-color: khaki;
}

#content-save .videobox {
  padding: 1em;
  background-color: honeydew;
}
